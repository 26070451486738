import AboutUs from "../Component/AboutUs";
import Footer from "../Component/Footer";
import Hero from "../Component/Hero";
import Navbar from "../Component/Navbar";
import AboutImg from "../assets/about.jpg";


function About() {
  return (
    <>
      <Navbar />
      <Hero cName="hero-mid" HeroImg={AboutImg} title="About" btnClass="hide" />
      <AboutUs/>
      <Footer />
    </>
  );
}
export default About;
