import Hero from "../Component/Hero";
import Navbar from "../Component/Navbar";
import AboutImg from "../assets/contact.avif";
import Footer from "../Component/Footer";
import ContactForm from "../Component/ContactForm";

function Connect() {
  return (
    <>
      <Navbar />
      <Hero cName="hero-mid"
        HeroImg={AboutImg}
        title="Connect" btnClass="hide"
      />
      <ContactForm />
      <Footer />
    </>

  );
}
export default Connect;
