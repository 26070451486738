import Hero from "../Component/Hero";
import Navbar from "../Component/Navbar";
import HomeImg from "../assets/Home.jpg";
import Destination from "../Component/Destination";
import Service from "../Component/Service";
import Footer from "../Component/Footer";

function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        HeroImg={HomeImg}
        title="Your Journey Your Story"
        text="Turning Dreams Into destination"
        buttonText="Travel Plan"
        url="/"
        btnClass="show"
      />
      <Destination />
      <Service />
      <Footer />
    </>
  );
}
export default Home;
