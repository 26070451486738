import "./AboutUsStyles.css"

function AboutUs() {
    return (
        <div className="about-container">
            <h1>Our History</h1>
            <p>Welcome to SDF Tour & Travlels, where our journey began with a passion for exploration and a commitment to exceptional service. Established in 2023, we've proudly served travelers with personalized experiences tailored to their dreams. Over the years, our dedication to creating unforgettable adventures has earned us a reputation for excellence in the industry. From humble beginnings, we've grown into a trusted name, offering a wide range of destinations and services to suit every traveler's needs. Our story is one of evolution, adapting to changing times while staying true to our core values of integrity, quality, and customer satisfaction. As we look to the future, we remain inspired by the spirit of adventure and the opportunity to continue shaping unforgettable travel experiences for our clients. Join us on this journey, and let's explore the world together.</p>
            <h1>Our Mission</h1>
            <p>At SDF Tour & Travlels, our mission is to curate unparalleled travel experiences that inspire, enrich, and connect individuals to the world around them. With a relentless dedication to excellence, we strive to exceed expectations by delivering personalized service, expert guidance, and unforgettable adventures tailored to each traveler's unique preferences. Rooted in integrity and fueled by passion, we are committed to fostering meaningful relationships with our clients, partners, and the destinations we explore. By embracing innovation and sustainability, we aim to lead the way in responsible travel, leaving a positive impact on both the communities we visit and the planet we share. Together, let's embark on a journey of discovery, where every trip becomes a story worth telling.</p>
            <h1>Our Vision</h1>
            <p>Our vision is to be the premier choice for discerning travelers seeking transformative experiences that transcend the ordinary. We aspire to continually elevate the art of travel by setting new standards of excellence in service, innovation, and sustainability. With a global perspective and a commitment to authenticity, we envision a world where exploration fosters understanding, compassion, and connection across cultures. Guided by our passion for adventure and driven by a spirit of discovery, we aim to inspire wanderlust and empower individuals to embrace the beauty and diversity of our planet. Through visionary leadership and a relentless pursuit of excellence, we strive to redefine the possibilities of travel, creating memories that last a lifetime and stories that inspire generations to come.</p>
        </div>

    )
}
export default AboutUs;