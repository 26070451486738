import "./FooterStyles.css"

const Footer = () =>{
    return(
        <div className="footer">
            <div className="top">
                <div>
                    <h1>S D F Tour & Travels L.L.C.</h1>
                    <p>Select our services for a hassle-free journey tailored to your needs.</p>
                </div>
                <div>
                    <a href="https://wa.me/message/7G5WGJIIIPIGI1">
                        <i className="fa-brands fa-whatsapp-square"></i>
                    </a>
                    <a href="https://www.facebook.com/people/SDF-Tour-Travels/61557905392822/">
                        <i className="fa-brands fa-facebook-square"></i>
                    </a>
                    <a href="https://www.instagram.com/sdftourandtravels/">
                        <i className="fa-brands fa-instagram-square"></i>
                    </a>
                    <a href="https://www.twitter.com">
                        <i className="fa-brands fa-twitter-square"></i>
                    </a>
                </div>
            </div>
            <div className="bottom">
                <div>
                    <h4>Project</h4>
                    <a href="/">Home</a>
                    <a href="/">About</a>
                    <a href="/">Services</a>
                    <a href="/">Contact</a>
                </div>
                <div>
                    <h4>Destinations</h4>
                    <a href="/">Dubai</a>
                    <a href="/">Qatar</a>
                    <a href="/">Oman</a>
                    <a href="/">Egypt</a>
                </div>
                <div>
                    <h4>Tour</h4>
                    <a href="/">Maldives</a>
                    <a href="/">Nepal</a>
                    <a href="/">Mauritius</a>
                    <a href="/">Malaysia</a>
                </div>
                <div>
                    <h4>Others</h4>
                    <a href="/">Term of Service</a>
                    <a href="/">Privacy Policy</a>
                    <a href="/">Licence</a>
                    <a href="/">Tourism</a>
                </div>
            </div>
        </div>
    )
}
export default Footer;