import dubai1 from "../assets/uae-1.jpg";
import dubai2 from "../assets/uae-2.jpg";
import thai1 from "../assets/thailand1.jpg";
import thai2 from "../assets/thailand2.jpg";
import mald1 from "../assets/maldiv1.avif"
import mald2 from "../assets/maldiv2.avif"
import sril1 from "../assets/srilanka1.jpg"
import sril2 from "../assets/srilanka2.jpg"
import nepal1 from "../assets/nepal1.jpg"
import nepal2 from "../assets/nepal2.jpg"
import swiss1 from "../assets/swis1.jpg"
import swiss2 from "../assets/swis2.jpg"
import armenia1 from "../assets/armenia1.jpg"
import armenia2 from "../assets/armenia2.jpg"
import uzbekistan1 from "../assets/uzbekistan1.jpg"
import uzbekistan2 from "../assets/uzbekistan2.jpg"
import azerbaijan1 from "../assets/azerbaijan1.jpg"
import azerbaijan2 from "../assets/azerbaijan2.jpg"
import singapore1 from "../assets/singapore1.avif"
import singapore2 from "../assets/singapore2.jpg"
import australia1 from "../assets/australia1.jpg"
import australia2 from "../assets/australia2.jpg"
import DestinationData from "./DestinationData";
import "./DestinationStyles.css";

const Destination = () => {
  return (
    <div className="destination">
      <h1>Popular Destination</h1>
      <p>Tours Give You to Opportunity to See a Lot, Within a Time Frame</p>

      <DestinationData
        className="first-desc"
        heading="Dubai"
        text="    Urban Marvel: Welcome to Dubai, a dazzling oasis where modern skyscrapers meet ancient traditions in the heart of the desert.
        Iconic Landmarks: Marvel at architectural wonders such as the Burj Khalifa, the world's tallest building, and the sail-shaped Burj Al Arab.
        Luxury Shopping: Indulge in a shopping spree at world-class malls, souks, and boutiques, offering everything from haute couture to traditional treasures.
        Cultural Fusion: Immerse yourself in Dubai's vibrant cultural scene, where East meets West in a tapestry of art, music, and cuisine.
        Desert Adventures: Embark on thrilling desert safaris, dune bashing, and camel rides amidst the stunning landscapes of the Arabian Desert.
        Waterfront Marvels: Explore Dubai's picturesque waterfront, home to the stunning Dubai Marina, Palm Jumeirah, and bustling waterfront promenades.
        Family Fun: Treat your family to unforgettable experiences at world-class theme parks, aquariums, and entertainment complexes.
        Gastronomic Delights: Delight your palate with a culinary journey through Dubai's diverse dining scene, from gourmet restaurants to local eateries.
        Epic Nightlife: Experience the electrifying nightlife of Dubai, with rooftop bars, clubs, and lounges offering spectacular views and entertainment."
        img1={dubai1}
        img2={dubai2}

      />

      <DestinationData
        className="first-desc-reverse"
        heading="Thailand"
        text="Enchanting Landscapes: Welcome to Thailand, where lush jungles, pristine beaches, and ancient temples create a picturesque paradise.
        Cultural Marvels: Explore the rich tapestry of Thai culture through majestic temples, vibrant festivals, and traditional Thai dance performances.
        Culinary Delights: Indulge in a gastronomic adventure with Thai cuisine, renowned for its bold flavors, aromatic spices, and street food delights.
        Tropical Retreats: Relax on sun-kissed beaches and turquoise waters in world-famous destinations like Phuket, Krabi, and Koh Samui.
        Elephant Encounters: Experience the wonder of interacting with gentle giants at ethical elephant sanctuaries and conservation centers.
        Floating Markets: Immerse yourself in the vibrant colors and bustling atmosphere of Thailand's floating markets, offering fresh produce, handicrafts, and local delicacies.
        Adventure Awaits: From jungle trekking and zip-lining to snorkeling and diving, Thailand offers thrilling adventures for every traveler.
        Spiritual Serenity: Find inner peace and tranquility at Thailand's sacred sites, including the iconic Wat Arun and the serene temples of Chiang Mai."
        img1={thai1}
        img2={thai2}
      />
      <DestinationData
        className="first-desc"
        heading="Maldives"
        text="    Turquoise Oasis: Explore the Maldives, where azure waters meet powdery white beaches in a tropical paradise.
        Underwater Wonderland: Dive into vibrant coral reefs, home to diverse marine life, including majestic whale sharks and playful dolphins.
        Overwater Luxury: Experience indulgence in iconic overwater villas, offering breathtaking views and unparalleled serenity.
        Cultural Charms: Immerse yourself in Maldivian culture, from quaint fishing villages to ancient mosques and bustling markets.
        Island Adventures: Embark on island-hopping excursions to uncover hidden gems and local treasures.
        Wellness Retreat: Find tranquility in holistic spa treatments and wellness activities against the backdrop of pristine beaches.
        Responsible Tourism: Embrace sustainable practices and eco-friendly resorts committed to preserving the natural beauty of the Maldives.
        Romantic Escapes: Surrender to romance amidst secluded beaches, stunning sunsets, and intimate dining experiences.
        Adventure Awaits: From snorkeling and diving to surfing and sailing, the Maldives offers endless adventures for thrill-seekers.
        Memories to Last: Plan your escape to paradise and create unforgettable memories in the idyllic paradise of the Maldives."
        img1={mald1}
        img2={mald2}
      />
      <DestinationData
        className="first-desc-reverse"
        heading="Sri Lanka"
        text="Tropical Paradise: Welcome to Sri Lanka, where pristine beaches, lush rainforests, and ancient ruins await your exploration.
        Cultural Treasures: Immerse yourself in a rich tapestry of history and culture, with UNESCO World Heritage sites like Sigiriya Rock Fortress and the sacred city of Anuradhapura.
        Tea Plantations: Discover the scenic beauty of Sri Lanka's tea country, with verdant hillsides dotted with tea estates offering breathtaking views and aromatic brews.
        Wildlife Encounters: Embark on safari adventures in national parks such as Yala and Wilpattu, home to majestic elephants, elusive leopards, and exotic bird species.
        Spiritual Retreats: Find inner peace at ancient Buddhist temples, where colorful murals, towering stupas, and serene surroundings inspire reflection and meditation.
        Adventure Trails: Trek through misty mountains, cascading waterfalls, and dense forests along Sri Lanka's many hiking trails, offering thrilling adventures and breathtaking vistas.
        Culinary Delights: Indulge in a culinary journey through Sri Lanka's diverse cuisine, with spicy curries, fresh seafood, and tropical fruits tantalizing your taste buds."
        img1={sril1}
        img2={sril2}
      />
      <DestinationData
        className="first-desc"
        heading="Nepal"
        text="    Himalayan Majesty: Welcome to Nepal, home to the world's highest peaks, including Mount Everest, and breathtaking landscapes of towering mountains and verdant valleys.
        Cultural Riches: Immerse yourself in Nepal's rich cultural heritage, with ancient temples, monasteries, and UNESCO World Heritage sites like Kathmandu Valley.
        Adventure Playground: Embark on thrilling adventures, from trekking in the Himalayas to white-water rafting, paragliding, and jungle safaris in Chitwan National Park.
        Spiritual Sanctuaries: Find solace and enlightenment at sacred sites such as Swayambhunath Stupa and Lumbini, the birthplace of Lord Buddha.
        Warm Hospitality: Experience the genuine warmth and hospitality of the Nepalese people, whose smiles and hospitality welcome you with open arms.
        Breathtaking Scenery: Capture the stunning beauty of Nepal's landscapes, from terraced rice fields and alpine meadows to rhododendron forests and glacial lakes.
        Culinary Delights: Savor the flavors of Nepal's diverse cuisine, with hearty dal bhat, momos, and aromatic spices tantalizing your taste buds."
        img1={nepal1}
        img2={nepal2}
      />
      <DestinationData
        className="first-desc-reverse"
        heading="Switzerland"
        text="    Alpine Wonderland: Welcome to Switzerland, where snow-capped peaks, pristine lakes, and picturesque villages create a fairytale landscape.
        Skiing Paradise: Embark on exhilarating ski adventures in world-renowned resorts like Zermatt, Verbier, and St. Moritz, offering powder-perfect slopes and stunning panoramas.
        Scenic Train Journeys: Explore Switzerland's breathtaking scenery aboard iconic trains such as the Glacier Express and Bernina Express, winding through majestic mountains and charming valleys.
        Cultural Gems: Immerse yourself in Swiss culture and heritage, with medieval towns, charming lakeside cities, and iconic landmarks like Chillon Castle and the Matterhorn.
        Outdoor Adventures: From hiking and mountain biking to paragliding and kayaking, Switzerland offers endless outdoor pursuits amidst its stunning natural beauty.
        Gourmet Delights: Indulge in Swiss culinary delights, from creamy fondue and Swiss chocolate to hearty raclette, showcasing the country's rich gastronomic heritage.
        Relaxation and Wellness: Unwind in luxurious spas and wellness resorts nestled amidst the Alps, offering rejuvenating treatments and breathtaking views."
        img1={swiss1}
        img2={swiss2}
      />
      <DestinationData
        className="first-desc"
        heading="Armenia"
        text="    Ancient Treasures: Welcome to Armenia, a land steeped in rich history and dotted with ancient landmarks, including UNESCO World Heritage sites like Geghard Monastery and the ruins of Ani.
        Mountains and Monasteries: Explore the breathtaking landscapes of the Caucasus Mountains, where monasteries cling to rocky cliffs and alpine meadows stretch as far as the eye can see.
        Cultural Heritage: Immerse yourself in Armenian culture, with vibrant traditions, folk music, and dance, and a culinary scene bursting with flavors from the Mediterranean and Middle East.
        Spiritual Sanctuaries: Find solace and serenity at Armenia's sacred sites, including the iconic Khor Virap and Tatev Monastery, where spirituality intertwines with stunning natural beauty.
        Wine Country: Discover Armenia's burgeoning wine industry, with ancient winemaking traditions dating back thousands of years and vineyards nestled amidst picturesque valleys and hillsides.
        Outdoor Adventures: From hiking in Dilijan National Park to skiing in Tsaghkadzor, Armenia offers outdoor enthusiasts a wealth of activities amidst its stunning scenery.
        Warm Hospitality: Experience the legendary Armenian hospitality, where locals welcome visitors with open arms and heartfelt warmth, making every guest feel like family."
        img1={armenia1}
        img2={armenia2}
      />
      <DestinationData
        className="first-desc-reverse"
        heading="Uzbekistan"
        text="Silk Road Splendor: Welcome to Uzbekistan, a land of ancient wonders and cultural treasures along the legendary Silk Road, boasting majestic cities like Samarkand, Bukhara, and Khiva.
        Architectural Marvels: Explore the architectural wonders of Uzbekistan's historic cities, with dazzling mosques, madrasas, and minarets adorned in intricate tilework and majestic domes.
        Cultural Crossroads: Immerse yourself in Uzbekistan's rich cultural heritage, where vibrant bazaars, traditional crafts, and lively music and dance reflect a tapestry of influences from across the ages.
        Desert Adventures: Venture into the vast deserts of Uzbekistan, where camel treks and camping under starry skies offer a glimpse into the nomadic traditions of the region.
        Hospitality and Warmth: Experience the genuine hospitality of Uzbekistan's people, whose warmth and generosity welcome visitors with open arms, creating lasting connections and cherished memories.
        Culinary Delights: Indulge in Uzbek cuisine, with flavorful dishes like plov, shashlik, and samsa tantalizing your taste buds amidst the vibrant atmosphere of local eateries and teahouses.
        Craftsmanship and Artistry: Discover the artistry of Uzbekistan's craftsmen, from intricate ceramics and vibrant textiles to exquisite jewelry and ornate woodwork, preserving centuries-old traditions."
        img1={uzbekistan1}
        img2={uzbekistan2}
      />
      <DestinationData
        className="first-desc"
        heading="Azerbaijan"
        text="    Cultural Crossroads: Welcome to Azerbaijan, a land where Eastern charm and Western modernity harmonize amidst a backdrop of ancient heritage and futuristic architecture.
        Baku's Beauty: Explore the vibrant capital city of Baku, where the historic Old City, modern skyscrapers, and the iconic Flame Towers create a mesmerizing skyline.
        Historic Treasures: Immerse yourself in Azerbaijan's rich history, with ancient fortresses, palaces, and caravanserais scattered throughout the picturesque landscapes.
        Natural Wonders: Discover the diverse landscapes of Azerbaijan, from the lush forests and cascading waterfalls of Gabala to the otherworldly mud volcanoes of Gobustan.
        Culinary Delights: Indulge in Azerbaijani cuisine, with aromatic spices, savory kebabs, and flavorful plov tantalizing your taste buds in bustling bazaars and cozy cafes.
        Hospitality and Warmth: Experience the legendary hospitality of the Azerbaijani people, whose warmth and generosity welcome visitors with open arms, creating unforgettable memories.
        Cultural Festivals: Immerse yourself in Azerbaijan's vibrant cultural scene, with traditional music, dance, and festivals celebrating the country's diverse heritage."
        img1={azerbaijan1}
        img2={azerbaijan2}
      />
      <DestinationData
        className="first-desc-reverse"
        heading="Singapore"
        text="Urban Marvel: Welcome to Singapore, a dynamic city-state where modern skyscrapers blend seamlessly with lush greenery and vibrant cultural heritage.
        Garden City: Explore Singapore's iconic Gardens by the Bay, where futuristic supertrees and exotic flora create a breathtaking urban oasis.
        Cultural Fusion: Immerse yourself in Singapore's diverse culture, with bustling ethnic neighborhoods like Chinatown, Little India, and Kampong Glam offering a taste of the city's multicultural heritage.
        Culinary Delights: Indulge in Singapore's world-renowned food scene, from hawker centers serving up savory street food to Michelin-starred restaurants showcasing culinary innovation.
        Shopping Paradise: Shop till you drop in Singapore's shopping districts, from luxury boutiques on Orchard Road to eclectic markets in Bugis and Haji Lane.
        Iconic Landmarks: Snap photos at iconic landmarks like Marina Bay Sands, Merlion Park, and the historic Raffles Hotel, capturing the essence of Singapore's skyline and history.
        Family Fun: Treat your family to unforgettable experiences at attractions like Universal Studios Singapore, S.E.A. Aquarium, and the Singapore Zoo, offering fun for all ages."
        img1={singapore1}
        img2={singapore2}
      />
      <DestinationData
        className="first-desc"
        heading="Australia"
        text="Natural Wonders: Welcome to Australia, where stunning landscapes await, from the vast Outback to the lush rainforests of Queensland and the iconic Great Barrier Reef.
        Cityscapes and Culture: Explore vibrant cities like Sydney, Melbourne, and Brisbane, where world-class museums, galleries, and culinary scenes thrive against a backdrop of iconic landmarks.
        Outdoor Playground: Embark on outdoor adventures, from surfing along the Gold Coast to hiking in the Blue Mountains and encountering wildlife on Kangaroo Island.
        Indigenous Heritage: Discover Australia's rich Indigenous culture, with ancient rock art, Dreamtime stories, and immersive cultural experiences offering insight into the world's oldest living culture.
        Wine and Dine: Indulge in Australia's diverse culinary scene, with fresh seafood, gourmet produce, and award-winning wines tantalizing taste buds in picturesque vineyards and waterfront restaurants.
        Wildlife Encounters: Encounter unique wildlife, from cuddly koalas and playful kangaroos to majestic whales and colorful reef fish, in their natural habitats across the country.
        Road Trip Adventures: Hit the open road on epic road trips like the Great Ocean Road or the Red Centre Way, traversing rugged coastlines, dramatic landscapes, and iconic landmarks."
        img1={australia1}
        img2={australia2}
      />
    </div>
  );
};
export default Destination;
