import Hero from "../Component/Hero";
import Navbar from "../Component/Navbar";
import AboutImg from "../assets/about-1.jpg";
import Service from "../Component/Service"
import Footer from "../Component/Footer";

function Services() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        HeroImg={AboutImg}
        title="Services"
        btnClass="hide"
      />
      <Service />
      <Footer />
    </>
  );
}
export default Services;
