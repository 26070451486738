import "./ServiceStyles.css";
import ServiceData from "./ServiceData";
import ser1 from "../assets/dubai-visa2.jpg"
import ser2 from "../assets/flight1.jpg"
import ser3 from "../assets/dubai-exc1.png"
import ser4 from "../assets/umrah.jpg"
import ser5 from "../assets/europe3.jpg"
import ser6 from "../assets/holiday.jpg"
import ser7 from "../assets/honemoon.jpg"
import ser8 from "../assets/US-UK.jpg"

function Service() {
    return (
        <div className="service">
            <h1>Our Services</h1>
            <p>You Can Discover Unique Destinations Using Google Maps.</p>
            <div className="servicecard">
                <ServiceData
                    image={ser1}
                    heading="Dubai Visa"
                    text="Welcome to our esteemed travel agency, your one-stop destination for Dubai tourist visas tailored to your needs. We offer a comprehensive range of visa options, including 30, 60, and 90-day durations, as well as single and multiple entry visas. In addition, we facilitate freelance and family visas with expedited processing and competitive pricing. Trust us to streamline your visa application process and ensure your journey to Dubai is smooth and efficient."
                />
                <ServiceData
                    image={ser2}
                    heading="Flight Bookings"
                    text="Welcome to our distinguished travel agency, your ultimate source for global flight bookings at unbeatable prices. With access to a vast network of airlines, we specialize in securing the best deals tailored to your preferences. Whether you're jet-setting for business or leisure, our dedicated team ensures seamless and cost-effective travel solutions. Trust us to handle every aspect of your flight arrangements with professionalism and efficiency, allowing you to focus on your journey ahead."
                />
                <ServiceData
                    image={ser3}
                    heading="Dubai Excursions"
                    text="Experience the best of Dubai with our comprehensive excursion ticket booking and transport arrangement services. From thrilling desert safaris to awe-inspiring city tours, we offer a wide range of excursions tailored to suit every traveler's preferences. Our dedicated team ensures hassle-free booking and seamless transportation, allowing you to immerse yourself in the wonders of Dubai with ease. Trust us to curate unforgettable experiences and make your Dubai adventures truly memorable."
                />
                <ServiceData
                    image={ser4}
                    heading="Hajj & Umrah"
                    text="your premier destination for expert guidance on Hajj and Umrah visas, along with Saudi one-year multi-entry tourist visas. Our comprehensive services ensure a smooth visa application process, tailored to meet your pilgrimage or leisure travel needs. From navigating documentation requirements to providing invaluable insights, our knowledgeable team is dedicated to facilitating your journey with professionalism and efficiency. Trust us to offer personalized assistance and ensure a rewarding and hassle-free travel experience to Saudi Arabia."
                />
            </div>
            <div className="servicecard">
                <ServiceData
                    image={ser5}
                    heading="Europe Visa"
                    text="As a dedicated travel agency specializing in European travel, we offer comprehensive guidance and meticulous assistance in obtaining Schengen country visas. Our seasoned team is committed to crafting meticulous visa applications, ensuring all documents are meticulously prepared to meet the stringent requirements. With our professional expertise, we streamline the visa process, providing our clients with peace of mind and confidence as they embark on their European adventures."
                />
                <ServiceData
                    image={ser6}
                    heading="Holiday"
                    text="we curate seamless holiday experiences complete with flight and hotel bookings. Our agency meticulously coordinates every aspect of your journey, from selecting the ideal accommodations to securing the most convenient flight options. With a keen eye for detail and a commitment to excellence, we tailor each itinerary to exceed our clients' expectations, ensuring unforgettable travel experiences throughout Europe. Let us handle the logistics while you focus on making memories that last a lifetime."
                />
                <ServiceData
                    image={ser7}
                    heading="HoneyMoon"
                    text="Embark on the journey of a lifetime with our meticulously crafted honeymoon packages, where every detail is tailored to perfection. From enchanting destinations to luxurious accommodations, we ensure that your romantic getaway is nothing short of extraordinary. Our expert team orchestrates seamless experiences, encompassing everything from flights and accommodations to personalized touches that create unforgettable moments. Let us take care of all the arrangements, allowing you to focus solely on celebrating love and creating cherished memories that will last a lifetime."
                />
                <ServiceData
                    image={ser8}
                    heading="USA & UK"
                    text="Navigating the complexities of visa applications for the UK and US is made effortless with our comprehensive guidance services. Our expert team offers invaluable assistance, ensuring that every aspect of the visa process is handled with precision and expertise. From compiling necessary documents to providing up-to-date information on visa requirements and procedures, we are committed to facilitating a smooth and successful application experience. Trust in our proficiency to guide you through the intricacies of obtaining visas, allowing you to embark on your journey with confidence and peace of mind."
                />
            </div>
        </div>
    );
}
export default Service;